let routes = [
  {
    path: "/checkout",
    name: "Checkout",
    meta: {
      requiresAuth: true,
      slug: "checkout"
    },
    component: () => import("@/views/checkout/Checkout.vue")
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      requiresAuth: true,
      slug: "payment"
    },
    component: () => import("@/views/checkout/Payment.vue")
  },
  {
    path: "/cart/payment-error/:orderId",
    name: "PaymentError",
    meta: {
      requiresAuth: true,
      slug: "cart/payment-error"
    },
    component: () => import("@/views/checkout/PaymentCompleted.vue")
  },
  {
    path: "/cart/payment-completed/:orderId",
    name: "PaymentCompleted",
    meta: {
      requiresAuth: true,
      slug: ["payment-completed"]
    },
    component: () => import("@/views/checkout/PaymentCompleted.vue")
  },
  {
    path: "/my",
    name: "Profile",
    meta: {
      requiresAuth: true,
      breadCrumb: "Area personale",
      slug: "dashboard"
    },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "paymentduedate",
        name: "PaymentDueDate",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie scadenze",
          slug: ["duedate"]
        },
        component: () => import("@/views/profile/PaymentDueDate.vue")
      }
    ]
  }
];

export default routes;

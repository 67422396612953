let routes = [
  {
    path: "/negozi-e-volantini/negozi/:warehouseId",
    meta: {
      breadCrumb: "Negozi e volantini"
    },
    component: () => import("@/views/store/WarehouseDetail.vue")
  },
  {
    name: "WarehouseFlyer",
    path: "/negozi-e-volantini/volantini/:flyerId",
    meta: {
      breadCrumb: "Negozi e volantini",
      site: "site"
    },
    component: () => import("@/views/store/WarehouseFlyer.vue")
  },
  {
    //gestione gerarchica del catalogo per poter gestire il reset filters all'ingresso.
    path: "/catalog",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    beforeEnter(to, from, next) {
      global.EventBus.$emit("resetFilters");
      next();
    },
    children: [
      {
        path: "/search",
        name: "Search",
        component: () => import("@/views/catalog/Search.vue"),
        props: route => ({
          query: route.params,
          parent_category_id: route.params.parent_category_id,
          filter: route.params.filter,
          mode: "search"
        }),
        meta: { slug: "search" }
      },
      {
        path: "/fast-search",
        name: "FastSearch",
        component: () => import("@/views/catalog/FastSearch.vue"),
        props: route => ({
          query: route.query.q
        }),
        meta: { slug: "fast-search" }
      },
      {
        path: "/product/:slug",
        name: "Product",
        component: () => import("@/views/catalog/Product.vue"),
        props: true
      },
      {
        path: "/*",
        name: "Category",
        component: () => import("@/views/catalog/Category.vue"),
        meta: {
          keepAlive: true
        },
        //commento
        props: route => ({
          slug: route.params.pathMatch,
          categoryId: route.query.categoryId
        })
      }
    ]
  }
];

export default routes;

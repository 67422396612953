const routes = [
  {
    path: "/page/:pageName",
    name: "Page",
    component: () => import("@/views/wordpress/Page.vue")
  },
  {
    path: "/wp-search",
    name: "WpSearch",
    component: () => import("@/views/wordpress/Search.vue"),
    props: route => ({
      query: route.params.q,
      subtype: route.params.subtype
    }),
    meta: { slug: "search" }
  },
  // {
  //   path: "/faq",
  //   meta: {
  //     breadCrumb: "Faq"
  //   },
  //   component: {
  //     render(c) {
  //       return c("router-view");
  //     }
  //   },
  //   children: [
  //     {
  //       name: "FaqCategory",
  //       path: "",
  //       meta: { pageName: "faq-list" },
  //       component: () => import("@/views/wordpress/FaqCategory.vue")
  //     },
  //     {
  //       path: ":categoryName",
  //       name: "FaqList",
  //       meta: {
  //         breadCrumb: "Dettaglio Faq"
  //       },
  //       component: () => import("@/views/wordpress/FaqList.vue")
  //     }
  //   ]
  // },
  {
    path: "/form/:formId",
    name: "NinjaForms",
    component: () => import("@/commons/components/ninjaForms/NinjaForm.vue"),
    props: true
  },
  {
    path: "/news",
    meta: {
      breadCrumb: "News"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "NewsList",
        path: "",
        component: () => import("@/views/wordpress/NewsList.vue"),
        meta: { pageName: "post-wp" },
        props: route => ({
          cat: route.query.cat,
          type: "post",
          pageNum: route.query.page
        })
      },
      {
        path: ":postName",
        name: "NewsDetail",
        meta: {
          breadCrumb: "Dettaglio News"
        },
        props: { type: "posts" },
        component: () => import("@/views/wordpress/NewsDetail.vue")
      }
    ]
  },
  {
    path: "/approfondimenti",
    meta: {
      breadCrumb: "Approfondimenti"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "ApprofondimentoList",
        path: "",
        component: () => import("@/views/wordpress/NewsList.vue"),
        meta: { pageName: "approfondimento-wp" },
        props: route => ({
          cat: route.query.cat,
          type: "approfondimento"
        })
      },
      {
        path: ":postName",
        name: "ApprofondimentoDetail",
        meta: {
          breadCrumb: "Dettaglio Approfondimento"
        },
        props: { type: "approfondimento" },
        component: () => import("@/views/wordpress/NewsDetail.vue")
      }
    ]
  },
  {
    path: "/video",
    meta: {
      breadCrumb: "Video"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "VideoList",
        path: "",
        component: () => import("@/views/wordpress/NewsList.vue"),
        meta: { pageName: "video-wp" },
        props: route => ({
          cat: route.query.cat,
          type: "video"
        })
      },
      {
        path: ":postName",
        name: "VideoDetail",
        meta: {
          breadCrumb: "Dettaglio Video"
        },
        props: { type: "video" },
        component: () => import("@/views/wordpress/NewsDetail.vue")
      }
    ]
  },
  {
    path: "/appuntino",
    meta: {
      breadCrumb: "L'appuntino",
      meta: "site"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "AppuntinoList",
        path: "",
        component: () => import("@/views/wordpress/RecipeList.vue"),
        meta: { pageName: "appuntino-wp" },
        props: route => ({
          s: route.query.s,
          course: route.query.course,
          cuisine: "appuntino",
          video: route.query.video,
          pageNum: route.query.page
        })
      },
      {
        path: ":postName",
        name: "AppuntinoDetail",
        meta: {
          breadCrumb: "Dettaglio Ricetta",
          site: "site"
        },
        component: () => import("@/views/wordpress/RecipeDetail.vue")
      }
    ]
  },
  {
    path: "/mixology",
    meta: {
      breadCrumb: "Mixology"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "MixologyList",
        path: "",
        component: () => import("@/views/wordpress/RecipeList.vue"),
        meta: {
          pageName: "mixology-wp"
        },
        props: route => ({
          s: route.query.s,
          course: route.query.course,
          cuisine: "mixology",
          video: route.query.video,
          pageNum: route.query.page
        })
      },
      {
        path: ":postName",
        name: "MixologyDetail",
        meta: {
          breadCrumb: "Dettaglio Mixology"
        },
        component: () => import("@/views/wordpress/RecipeDetail.vue")
      }
    ]
  },
  {
    path: "/richiamo-prodotti",
    meta: {
      breadCrumb: "Richiamo prodotti"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "ProductRecall",
        path: "",
        component: () => import("@/views/wordpress/ProductRecall.vue")
      }
    ]
  }
];

export default routes;

const routes = [
  {
    path: "/punti-vendita/:slug",
    meta: {
      breadCrumb: "Punto vendita",
      site: "site"
    },
    component: () => import("@/views/store/WarehouseDetail.vue")
  }
];

export default routes;

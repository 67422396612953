var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 category-tree-container"},[_c('div',{staticClass:"header d-flex flex-column"},[_c('v-row',{staticClass:"ma-2",attrs:{"no-gutters":"","justify":"space-between"}},[_c('img',{staticClass:"px-0 logo",attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? '42px' : '63',"src":"/logo/logo_claim.png","alt":"Logo Zona.eu"}}),_c('v-btn',{staticClass:"white pa-0",attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("$close")])],1)],1),_c('h4',{staticClass:"secondary--text ma-2 d-none d-md-inline"},[_vm._v(" "+_vm._s(_vm.$t("navbar.categoryTitle"))+" ")]),_c('v-tabs',{staticClass:"px-2 mb-4 d-inline d-md-none",attrs:{"hide-slider":"","active-class":"activeTab"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{key:"categories"},[_c('h6',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.$t("navbar.products")))])]),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-tab',{key:"info"},[_c('h6',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.$t("navbar.links")))])]):_vm._e()],1)],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{key:"categories"},[_c('v-list',{staticClass:"category-tree px-2 px-sm-0"},_vm._l((_vm.categories),function(category){return _c('v-list-group',{key:category.categoryId,staticClass:"category1",attrs:{"color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"font-weight-bold"},[_c('router-link',{attrs:{"to":{
                  name: 'Category',
                  params: { pathMatch: category.slug }
                },"event":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nop.apply(null, arguments)}}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)]},proxy:true}],null,true)},[_vm._l((category.categories),function(category2){return [_c('v-list-item',{key:category2.categoryId,staticClass:"category2",attrs:{"link":"","to":{
                name: 'Category',
                params: { pathMatch: category2.slug }
              }}},[_c('v-list-item-title',[_vm._v(_vm._s(category2.name))])],1)]})],2)}),1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-tab-item',{key:"info"},[_c('v-list',{staticClass:"info-tab px-2"},[_vm._l((_vm.infoLinks),function(link,infoLinkId){return _c('v-list-item',{key:infoLinkId,staticClass:"link accent mb-1",attrs:{"href":link.itemUrl},on:{"click":_vm.clicked}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"default"}},[_vm._v(_vm._s(`$${link.classes}`))])],1),_c('v-list-item-title',{staticClass:"text--bold",class:link.classes},[_vm._v(" "+_vm._s(link.name)+" ")])],1)}),_vm._l((_vm.primaryLinks),function(item,primaryLinkIdx){return [(item.templateNavItems && item.templateNavItems.length <= 0)?_c('v-list-item',{key:primaryLinkIdx,staticClass:"menuItem",attrs:{"href":item.itemUrl},on:{"click":_vm.clicked}},[_c('v-list-item-title',{staticClass:"ml-0",class:item.classes},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_c('v-list-group',{key:primaryLinkIdx,staticClass:"menuItem",attrs:{"color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(item.name))])])]},proxy:true}],null,true)},_vm._l((item.templateNavItems),function(child,childIdx){return _c('div',{key:childIdx},[_c('v-list-item',{attrs:{"link":"","href":child.itemUrl},on:{"click":_vm.clicked}},[_c('v-list-item-title',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(child.name)+" ")])],1),(child.templateNavItems?.length)?_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((child.templateNavItems),function(child2,idx3){return _c('v-list-item',{key:idx3,staticClass:"text-caption default--text ml-3",class:child2.classes,attrs:{"link":"","href":child2.itemUrl || '#'},on:{"click":_vm.clicked}},[_c('span',{class:child2.classes},[_vm._v(_vm._s(child2.name))])])}),1):_vm._e()],1)}),0)]})],2)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }